import React, { useState, useEffect } from 'react'
import { LineChart, Line, ResponsiveContainer, CartesianGrid, XAxis, YAxis, Tooltip, Legend } from 'recharts'
import { Card, CardContent, CardHeader, CardMedia, Grid, makeStyles, TextField } from '@material-ui/core'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import RateReviewIcon from '@material-ui/icons/RateReview'
import PeopleIcon from '@material-ui/icons/People'
import CropFreeIcon from '@material-ui/icons/CropFree'
import CardWithIcon from './CardWithIcon'
import { Loading, useDataProvider } from 'react-admin'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex'
  },
  headerImage: {
    display: 'flex',
    flexDirection: 'column',
    width: 39,
    height: 52
  },
  statsCard: {
    display: 'flex'
  }
}))

export interface Stats {
  products: number
  reviews: number
  users: number
  scans: number
  dates: Array<any>
}

export default function Dashboard () {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const [days, setDays] = useState<number>(15)
  const [stats, setStats] = useState<Stats>()

  const onDaysChange = (daysI: number) => {
    setDays(daysI)
    dataProvider.getOne(`stats/${daysI}`, { id: '' }).then(({ data }) => {
      setStats(data as any)
    })
  }

  useEffect(() => {
    if (!stats) {
      dataProvider.getOne(`stats/${days}`, { id: '' }).then(({ data }) => {
        setStats(data as any)
      })
    }
  })
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Card className={classes.header}>
          <CardMedia className={classes.headerImage} image="https://s3.amazonaws.com/vegan-cdn.innoverso.io/logo.png" title="V My App"/>
          <CardHeader title="V My App"/>
        </Card>
      </Grid>
      <Grid item xs={3}>
        <CardWithIcon to="/products" icon={LocalOfferIcon} title={'Productos:'} subtitle={`${stats ? stats.products : '...'}`}/>
      </Grid>
      <Grid item xs={3}>
        <CardWithIcon to="/reviews" icon={RateReviewIcon} title={'Comentarios:'} subtitle={`${stats ? stats.reviews : '...'}`}/>
      </Grid>
      <Grid item xs={3}>
        <CardWithIcon to="/" icon={PeopleIcon} title={'Usuarios:'} subtitle={`${stats ? stats.users : '...'}`}/>
      </Grid>
      <Grid item xs={3}>
        <CardWithIcon to="/" icon={CropFreeIcon} title={'Escaneos:'} subtitle={`${stats ? stats.scans : '...'}`}/>
      </Grid>
      <Grid item xs={12}>
        <Card>
          <CardHeader title="Registro por fecha" action={<TextField label="Días" variant="outlined" type="number" value={days} onChange={event => onDaysChange(parseInt(event.target.value))} inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }} />}/>
          <CardContent style={{ height: 500 }}>
          {
            stats
              ? <ResponsiveContainer>
                  <LineChart data={stats.dates}>
                    <CartesianGrid strokeDasharray="3 3"/>
                    <XAxis dataKey="date" angle={25} padding={{ left: 20, right: 20 }} name={'Fecha'} />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Line type="monotone" dataKey="products" stroke="#0b84a5" fill="#0b84a5" strokeWidth={3}/>
                    <Line type="monotone" dataKey="reviews" stroke="#f6c85f" fill="#f6c85f" strokeWidth={3}/>
                    <Line type="monotone" dataKey="users" stroke="#6f4e7c" fill="#6f4e7c" strokeWidth={3}/>
                    <Line type="monotone" dataKey="scans" stroke="#9dd866" fill="#9dd866" strokeWidth={3}/>
                  </LineChart>
                </ResponsiveContainer>
              : <Loading />
          }
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
