import { fetchUtils } from 'react-admin'
import { AuthProvider } from 'react-admin-firebase/dist/misc/react-admin-models'
const queryString = require('query-string')

const apiUrl = process.env.REACT_APP_API_HOST

class DataProvider {
  private authProvider: AuthProvider;
  constructor (authProvider: AuthProvider) {
    this.authProvider = authProvider
  }

  async httpClient (url: string, options: any = {}) {
    if (!options.headers) {
      options.headers = new Headers({ Accept: 'application/json' })
    }
    const token = await this.authProvider.getJWTToken()
    options.headers.set('Authorization', token)
    return fetchUtils.fetchJson(url, options)
  }

  async getList (resource: string, params: any): Promise<any> {
    let url = `${apiUrl}/${resource}`
    if (['reviews'].includes(resource)) url += '/approval'
    const query: any = {
    }
    if (params && params.pagination) {
      const { page, perPage } = params.pagination
      query.page = page
      query.limit = perPage
    }

    if (params && params.filter) {
      for (const key in params.filter) {
        query[key] = params.filter[key]
      }
    }

    if (params && params.sort) {
      query.sortField = params.sort.field
      query.sortOrder = params.sort.order === 'ASC' ? 1 : -1
    }

    url += `?${queryString.stringify(query)}`

    return this.httpClient(url).then(({ json }: any) => ({
      data: json.data,
      total: json.meta ? json.meta.total : json.data.length
    }))
  }

  async getOne (resource: string, params: any): Promise<any> {
    return this.httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }: any) => ({
      data: json.data
    }))
  }

  async getMany (resource: string, params: any): Promise<any> {
    const url = `${apiUrl}/${resource}?ids=${params.ids.join(',')}`
    return this.httpClient(url).then(({ json }: any) => ({ data: json.data }))
  }

  async getManyReference (resource: string, params: any): Promise<any> {
    const url = `${apiUrl}/${resource}`

    return this.httpClient(url).then(({ headers, json }: any) => ({
      data: json.data,
      total: json.data.length
    }))
  }

  async update (resource: string, params: any): Promise<any> {
    let url = `${apiUrl}/${resource}/${params.id}`
    if (resource === 'reviews') url += '/approval'
    return this.httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify(params.data)
    }).then(({ json }: any) => ({ data: json.data }))
  }

  async updateMany (resource: string, params: any): Promise<any> {
    return new Promise((resolve, reject) => { resolve({ ids: [], data: {} }) })
  }

  async create (resource: string, params: any): Promise<any> {
    return this.httpClient(`${apiUrl}/${resource}`, {
      method: 'POST',
      body: JSON.stringify(params.data)
    }).then(({ json }: any) => ({
      data: json.data
    }))
  }

  async delete (resource: string, params: any): Promise<any> {
    return this.httpClient(`${apiUrl}/${resource}/${params.id}`, {
      method: 'DELETE'
    }).then(({ json }: any) => ({ data: json }))
  }

  async deleteMany (resource: string, params: any): Promise<any> {
    return this.httpClient(`${apiUrl}/${resource}`, {
      method: 'DELETE'
    }).then(({ json }: any) => ({ data: json }))
  }
}

export default DataProvider
