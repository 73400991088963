import React from 'react'
import { Paper } from '@material-ui/core'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, BooleanInput, SaveButton, Toolbar, DateField, NumberField, ReferenceField } from 'react-admin'

const ReviewExpand = ({ record }: any) => (
  <Paper>
    {record.comment}
  </Paper>
)

const ReviewTitle = ({ record }: any) => {
  return <span>Aprobar comentario</span>
}

function ReviewList (props: any) {
  return (
    <List {...props} bulkActionButtons={null} actions={null} pagination={null}>
      <Datagrid isRowSelectable={ record => false } isRowExpandable={row => row.comment } expand={<ReviewExpand />} rowClick="expand">
        <ReferenceField source="product" reference="products" link={false}>
          <TextField source="name" />
        </ReferenceField>
        <NumberField source="rate" />
        <DateField source="createdAt" showTime={true}/>
        <EditButton label="Aprobar" />
      </Datagrid>
    </List>
  )
}

function ReviewApproveToolbar (props: any) {
  return (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  )
}

function ReviewApprove (props: any) {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<ReviewTitle/>}>
      <SimpleForm submitOnEnter={false} toolbar={<ReviewApproveToolbar/>}>
        <NumberField source="rate" />
        <TextField source="comment" />
        <BooleanInput source="isApproved" defaultValue={true}/>
      </SimpleForm>
    </Edit>
  )
}

export { ReviewList, ReviewApprove }
