import React from 'react'
import { Admin, Resource } from 'react-admin'
import { FirebaseAuthProvider } from 'react-admin-firebase'
import DataProvider from './services/DataProvider'
import { ProducList, ProductApprove } from './components/ProductsComponents'
import { ReviewList, ReviewApprove } from './components/ReviewsComponents'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import RateReviewIcon from '@material-ui/icons/RateReview'
import Dashboard from './components/Dashboard'

const SERVICE_ACCOUNT = JSON.parse(process.env.REACT_APP_FIREBASE_CERT!)
const authProvider = FirebaseAuthProvider(SERVICE_ACCOUNT, {})
const dataProvider = new DataProvider(authProvider)

function App () {
  return (
    <Admin dashboard={Dashboard} authProvider={authProvider} dataProvider={dataProvider} title="V My App Admin">
      <Resource name="products" icon={LocalOfferIcon} list={ProducList} edit={ProductApprove} options={ { label: 'Productos' } } />
      <Resource name="reviews" icon={RateReviewIcon} list={ReviewList} edit={ReviewApprove} options={ { label: 'Comentarios pendientes' } }/>
    </Admin>
  )
}

export default App
