import React from 'react'
import { Grid, Paper } from '@material-ui/core'
import { List, Datagrid, TextField, EditButton, Edit, SimpleForm, BooleanInput, SaveButton, Toolbar, DateField, PaginationActions, Pagination, NumberField, BooleanField, TextInput } from 'react-admin'

const ProductExpand = ({ record }: any) => (
  <Grid container spacing={2}>
    <Grid item xs={3}>
      <Paper>
        <img src={record.picture} title={record.name} style={{ maxHeight: 200, display: record.picture ? 'block' : 'none' }}/>
      </Paper>
    </Grid>
    <Grid item xs={9}>
      <Paper>{record.description}</Paper>
    </Grid>
  </Grid>
)

const postFilters = [
  <TextInput key="barcode" label="Código de barras" source="barcode" alwaysOn/>,
  <TextInput key="name" label="Nombre" source="name" alwaysOn/>,
  <BooleanInput key="isApproved" label="Aprobado" source="isApproved" />,
  <BooleanInput key="isVegan" label="Vegano" source="isVegan" />
]

const PostPaginationActions = (props:any) => <PaginationActions {...props} color="secondary" onChangePage={props.onPageChange}/>
const PostPagination = (props: any) => <Pagination {...props} ActionsComponent={PostPaginationActions} />

function ProducList (props: any) {
  return (
    <List {...props} bulkActionButtons={null} perPage={50} filters={postFilters} pagination={<PostPagination/>}>
      <Datagrid isRowSelectable={ record => false } isRowExpandable={row => row.description || row.picture } expand={<ProductExpand />} rowClick="expand">
        <TextField source="barcode" label="Código de barras"/>
        <TextField source="name" label="Nombre"/>
        <NumberField source="rate" label="Rate" sortable={false}/>
        <NumberField source="reviewsNumber" label="Numero de reviews" sortable={false}/>
        <DateField source="createdAt" showTime={true} label="Fecha"/>
        <BooleanField source="isVegan" label="Es vegano" sortable={false}/>
        <BooleanField source="isApproved" label="Esta aprobado" sortable={false}/>
        <EditButton label="Editar"/>
      </Datagrid>
    </List>
  )
}

const ProductTitle = ({ record }: any) => {
  return <span>Aprobar {`${record.name} (${record.barcode})`}</span>
}

function ProductApproveToolbar (props: any) {
  return (
    <Toolbar {...props} >
        <SaveButton />
    </Toolbar>
  )
}

function ProductApprove (props: any) {
  return (
    <Edit {...props} mutationMode="pessimistic" title={<ProductTitle/>}>
      <SimpleForm submitOnEnter={false} toolbar={<ProductApproveToolbar/>}>
        <TextField source="barcode" />
        <TextInput source="name" />
        <TextInput source="description" />
        <TextInput source="picture" />
        <BooleanInput source="isApproved"/>
        <BooleanInput source="isVegan"/>
      </SimpleForm>
    </Edit>
  )
}

export { ProducList, ProductApprove }
